import { useState, useEffect } from 'react';

import LimitReached from '../../Premium/LimitReached';
import UpgradeHero from '../../Premium/UpgradeHero';

import styles from './NewCard.module.css';

const NewCard = (props) => {
    const [text, setText] = useState(props.text);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [limitModal, setLimitModal] = useState(null);

    const submitHandler = () => {
        if (!text) return setError(true);
        props.newCardHandler(text);
        setOpen(false);
    }

    const toggleHandler = () => {
        if (open) return setOpen(false);
        if (!props.isPremium && props.cardsCount >= props.freeUsersCardCount) {
            setLimitModal('limit-reached');
            return;
        }
        if (!open) {
            setText('');
            setError(false);
            setOpen(true);
        }
    }

    useEffect(() => { if (text) setError(false) }, [text]);

    return (
        <>
            {open && (
                <div className={styles.overlay} onClick={() => setOpen(false)}>
                    <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                        <h2>New Card</h2>
                        <div className={styles.content}>
                            <textarea className={error ? styles.error : ''} value={text} onChange={(e) => setText(e.target.value)} autoFocus={true}></textarea>
                        </div>
                        <div className={styles.buttons}>
                            <button className={"button " + styles.cancel} onClick={() => setOpen(false)}>Cancel</button>
                            <button onClick={submitHandler} className={'button ' + styles.button} disabled={props.loading}>{props.loading ? <i className="fad fa-spinner-third"></i> : 'Create'}</button>
                        </div>
                    </div>
                </div>
            )}
            <button disabled={props.disabled} className={`${styles.toggle} ${open ? styles.tilted : ''}`} onClick={toggleHandler}>
                <i className="far fa-plus"></i>
            </button>
            {limitModal === 'upgrade-hero' && <UpgradeHero onCloseHandler={() => setLimitModal(false)} throwAlert={props.throwAlert} />}
            {limitModal === 'limit-reached' && <LimitReached limitType="card" openUpgradeHero={() => setLimitModal('upgrade-hero')} onCloseHandler={() => setLimitModal(false)} />}
        </>
    )
}

export default NewCard;