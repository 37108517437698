import { useRef } from 'react';

import styles from './SearchBar.module.css';

const SearchBar = (props) => {
    const searchInput = useRef(null);

    const onChangeHandler = (e) => {
        props.searchCardsHandler(e.target.value);
    }

    const clearQueryHandler = () => {
        searchInput.current.value = '';
        searchInput.current.focus();
        props.searchCardsHandler('');
    }

    return (
        <div className={styles.main}>
            <i className="fas fa-search"></i>
            <input ref={searchInput} className={styles.bar} type="text" placeholder="Search your cards" onChange={onChangeHandler} />
            <button onClick={clearQueryHandler}>
                <i className="far fa-times" style={{ color: '#000000' }}></i>
            </button>
        </div>
    );
}

export default SearchBar;