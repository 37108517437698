import BoardPlaceholder from './BoardPlaceholder';

import styles from './RecentBoards.module.css';

const RecentBoards = (props) => {
    return (
        <>
            <section className={styles.main}>
                <div className={styles.container}>
                    <h1>Recently Viewed</h1>
                    <div className={styles.boards}>
                        {props.boards.map(board => (
                            <BoardPlaceholder key={board._id} id={board._id} title={board.title} bgColor={board.backgroundColor} />
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default RecentBoards;