import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Modal from '../UI/Modal';

import Input from '../UI/Input';

const EditBoardTitle = (props) => {
    const navigate = useNavigate();

    const [name, setName] = useState(props.currentTitle);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const submitHandler = async () => {
        if (name.length === 0 || name.length > 16) return setError(true);

        setLoading(true);

        const { data } = await axios.post('/boards/change-title', { title: name, boardId: props.boardId }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });

        setLoading(false);

        if (data.status === 'ok') {
            props.setModalOpen(null)
            props.throwAlert('Board editted successfully!');
            props.changeTitle(name);
        } else {
            props.throwAlert(data.message);
        }

    }

    useEffect(() => {
        if (name.length > 1 && name.length <= 16) return setError(false);
    }, [name]);

    const inputChangeHandler = (e) => {
        const text = e.target.value;
        if (text.length <= 16) return setName(text);
        if (text.length > 16) {
            if (name.length - text.length === 1) return setName(text);
            return setName(name);
        }
    }

    return (
        <Modal title="Edit Title" actionHandler={submitHandler} actionText="Update" actionLoading={loading} onCloseHandler={() => props.setModalOpen(null)}>
            <Input error={error} errorText="Please enter a valid title." infoText={name.length + '/16'} type="text" placeholder="My Awesome Board" focusDefault={true} value={name} changeHandler={inputChangeHandler} />
        </Modal >
    )
}

export default EditBoardTitle;