import { useState } from 'react';
import axios from 'axios';

import Modal from '../UI/Modal';
import DeleteConfirmation from './DeleteConfirmation';

import styles from './Members.module.css';

const Members = (props) => {
    const [inputEmail, setInputEmail] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState([false, null]);

    const addNewMemberHandler = async () => {
        if (!inputEmail) return props.throwAlert('Please enter an email');
        if (!inputEmail.includes('@') || !inputEmail.includes('.')) return props.throwAlert('Please enter a valid email');

        const { data } = await axios.post('/boards/add-new-member', { boardId: props.boardId, email: inputEmail }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        if (data.status === 'error') return props.throwAlert(data.message);
        props.throwAlert('User added successfully');
        window.location.reload();
    }

    const removeMemberHandler = async (memberId) => {
        const { data } = await axios.post('/boards/remove-member', { boardId: props.boardId, memberId }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        if (data.status === 'error') return props.throwAlert(data.message);
        props.throwAlert('User removed successfully');
        window.location.reload();
    }

    return (
        <>
            <Modal title="Members" actionHandler={() => { }} actionText="Manage" onCloseHandler={props.onCloseHandler} cancelButtonText="Close" hideActionButton={true} >
                {props.isAdmin && (
                    <div className={styles.newMember}>
                        <input className="custom-input" type="email" placeholder="Email" onChange={(e) => setInputEmail(e.target.value)} value={inputEmail} />
                        <button className={styles.addBtn} onClick={addNewMemberHandler}>
                            <i className="fas fa-plus"></i>
                        </button>
                    </div>
                )}
                <div className={styles.members}>
                    {props.members.map((member, index) => (
                        <UserItem key={index} isAdmin={props.isAdmin} me={member.me} owner={member._id === props.owner} name={member.name} email={member.email} profilePicture={member.profilePicture} deleteConfHandler={() => setDeleteConfirmation([true, member._id])} />
                    ))}
                </div>
            </Modal>
            {deleteConfirmation[0] && <DeleteConfirmation type="member" onConfirmation={() => removeMemberHandler(deleteConfirmation[1])} onCloseHandler={() => setDeleteConfirmation([false, null])} loading={false} />}
        </>
    );
}

function UserItem({ me, isAdmin, owner, name, email, profilePicture, deleteConfHandler }) {
    return (
        <div className={styles.user}>
            <div className={styles.profile}>
                <div className={styles.avatar}>
                    <img src={profilePicture} alt={name} />
                </div>
                <div className={styles.name}>
                    <p>{name}{owner ? ' (owner)' : ''}{me ? ' (me)' : ''}</p>
                    <span style={{
                        color: '#828282',
                        fontSize: '13px',
                    }}>{email}</span>
                </div>
            </div>
            {isAdmin && !owner && (
                <button className={styles.removeBtn} onClick={deleteConfHandler}>
                    <i className="far fa-times"></i>
                </button>
            )}
            {!isAdmin && me && (
                <button className={styles.removeBtn} onClick={deleteConfHandler}>
                    <i className="far fa-times"></i>
                </button>
            )}
        </div>
    )
}

export default Members;