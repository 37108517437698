import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

import styles from './BoardPlaceholder.module.css';

const BoardPlaceholder = (props) => {
    const [hovering, setHovering] = useState(false);

    const navigate = useNavigate();

    const onClickHandler = () => {
        if (props.type === 'new-board-button') return props.newBoardHandler();
    }

    const deleteBoardHandler = async () => {
        const userResponse = window.confirm("Are you sure you want to delete this board?");
        if (!userResponse) return;

        const { data } = await axios.post('/boards/delete-board', { id: props.id }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });

        if (data.status === 'ok') {
            window.alert('Board deleted successfully');
            window.location.reload();
        } else {
            window.alert("ERROR:" + " " + data.message);
        }
    }

    return (
        <div onClick={onClickHandler} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} className={styles.board} style={{ backgroundColor: props.bgColor }}>
            {props.type !== 'new-board-button' && (
                <Link to={`/boards/${props.id}`} className={styles.main}>
                    {hovering && <span className={styles.hover}></span>}
                    <p className={styles.title}>
                        {props.title}
                    </p>
                </Link>
            )}
            {props.type !== 'new-board-button' && (
                <button className={styles.delete} onClick={deleteBoardHandler}>
                    <i className="far fa-trash-alt"></i>
                </button>
            )}
            {props.type === 'new-board-button' && <p className={styles.title} style={{ color: 'rgb(23, 43, 77)' }}>Create new board</p>}
        </div>
    )
}

export default BoardPlaceholder;