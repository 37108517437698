import styles from './Payment.module.css';

const Success = () => {
    document.title = "Payment successful - Chibaku"

    setTimeout(() => {
        window.location.href = "/boards";
    }, 1500);

    return (
        <div className={styles.main}>
            <i className={"far fa-check " + styles.check}></i>
            <h1>All done.</h1>
            <p>Your payment was successful. Redirecting...</p>
        </div>
    )
}

export default Success;