import { useState, useEffect } from 'react';

import DeleteConfirmation from '../../Modals/DeleteConfirmation';

import styles from './EditCard.module.css';

const EditCard = (props) => {
    const [text, setText] = useState(props.text);
    const [error, setError] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const editHandler = () => {
        if (!text) return setError(true);
        props.onCardEdit(props.id, text);
        props.onCloseHandler();
    }

    const deleteHandler = () => {
        props.onCardDelete(props.id);
        setDeleteModalOpen(false);
        props.onCloseHandler();
    }

    useEffect(() => { if (text) setError(false) }, [text]);

    useEffect(() => { window.getSelection().removeAllRanges() }, []);

    return (
        <>
            <div className={styles.overlay} onClick={props.onCloseHandler}>
                <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                    <h2>Edit Card</h2>
                    <div className={styles.content}>
                        <textarea className={error ? styles.error : ''} value={text} onChange={(e) => setText(e.target.value)} autoFocus={true}></textarea>
                    </div>
                    <div className={styles.buttons}>
                        <button className={"button " + styles.delete} onClick={() => setDeleteModalOpen(true)}>Delete</button>
                        <button className={"button " + styles.cancel} onClick={props.onCloseHandler}>Cancel</button>
                        <button className="button" onClick={editHandler}>Update</button>
                    </div>
                </div>
            </div>
            {deleteModalOpen && <DeleteConfirmation type="card" onCloseHandler={() => setDeleteModalOpen(false)} id={props.id} onConfirmation={deleteHandler} onCloseHandler={() => setDeleteModalOpen(false)} />}
        </>
    )
}

export default EditCard;