import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import SearchBar from './Cards/SearchBar';
import Card from './Cards/Card';

import EmbedCode from '../Modals/EmbedCode';

import Alert from '../Alert';

import styles from './Board.module.css';

const EmbedBoard = ({ embed }) => {
    const { id } = useParams();

    const [board, setBoard] = useState({ title: '', cards: [] });
    const [displayCards, setDisplayCards] = useState([]);
    const [alert, setAlert] = useState([false, '']);
    const [overallLoading, setOverallLoading] = useState(true);
    const [noCards, setNoCards] = useState(false);
    const [modalOpen, setModalOpen] = useState(null);
    const [selectedCards, setSelectedCards] = useState([]);

    const throwAlert = (message) => {
        setAlert([true, message]);
        setTimeout(() => {
            setAlert([false, '']);
        }, 2800);
    }

    useEffect(() => {
        setDisplayCards(board.cards);
        if (board.cards.length === 0) {
            setNoCards(true);
        } else {
            setNoCards(false);
        }
    }, [board]);

    useEffect(async () => {
        const { data } = await axios.post('/boards/get-embed-board-details', { id });
        if (data.status === 'ok') {
            if (data.board.length === 0) {
                setNoCards(true);
            } else {
                setBoard(data.board);
            }
        } else {
            throwAlert(data.message);
        }
        setOverallLoading(false);
    }, []);

    const cardDeleteHandler = async (cardId) => {
        console.log('Not allowed');
    }

    const cardEditHandler = async (cardId, text) => {
        console.log('Not allowed');
    }

    const searchCardsHandler = (query) => {
        if (query) {
            const matchedCards = board.cards.filter(card => card.text.toLowerCase().includes(query.toLowerCase()));
            if (matchedCards.length === 0) {
                setDisplayCards([]);
                setNoCards(true);
            } else {
                setDisplayCards(matchedCards);
                setNoCards(false);
            }
            return;
        }
        if (board.cards.length === 0) {
            setNoCards(true);
        } else {
            setNoCards(false);
        }
        setDisplayCards(board.cards);
    }

    const deleteBoardHandler = async () => {
        console.log('Not allowed');
    }

    const selectCardHandler = (cardId) => {
        if (selectedCards.includes(cardId)) {
            setSelectedCards(selectedCards.filter(id => id !== cardId));
        } else {
            setSelectedCards([...selectedCards, cardId]);
        }
    }

    const groupCopyHandler = () => {
        const selectedCardsText = selectedCards.map(id => board.cards.find(card => card.id === id).text);
        navigator.clipboard.writeText(selectedCardsText.join('\n'));
        throwAlert('Copied to clipboard');
        setSelectedCards([]);
    }

    return (
        <>
            {board.title && (
                <main className={styles.main} style={{
                    marginTop: '0.75rem',
                    marginBottom: '5rem',
                    maxWidth: '600px',
                    padding: '1rem',
                }}>
                    <div className={styles.header}>
                        {overallLoading ? <div style={{ height: '40px', maxWidth: '320px' }} className={styles.loadingDiv}></div> : <h2>{board.title}</h2>}
                    </div>
                    <SearchBar searchCardsHandler={searchCardsHandler} />
                    {noCards && !overallLoading ? (
                        <div className={styles.noCards}>
                            <p>No cards found. Create some.</p>
                        </div>
                    ) : null}
                    {overallLoading && (
                        <>
                            <div style={{ height: '70px' }} className={styles.loadingDiv}></div>
                            <div style={{ height: '130px' }} className={styles.loadingDiv}></div>
                            <div style={{ height: '90px' }} className={styles.loadingDiv}></div>
                            <div style={{ height: '180px' }} className={styles.loadingDiv}></div>
                        </>
                    )}
                    {!overallLoading && (
                        <div className={styles.cards}>
                            {displayCards.map(card => {
                                return <Card embededItem={true} key={card.id} id={card.id} text={card.text} boardId={id} onCardDelete={cardDeleteHandler} onCardEdit={cardEditHandler} selectCardHandler={() => selectCardHandler(card.id)} selected={selectedCards.includes(card.id)} />
                            })}
                        </div>
                    )}
                    {selectedCards.length !== 0 && (
                        <div className={styles.selectedCardsModal}>
                            <p>{selectedCards.length} cards selected</p>
                            <div className={styles.buttons}>
                                <button onClick={() => setSelectedCards([])} className={"button " + styles.cancel}>Clear</button>
                                <button onClick={groupCopyHandler} className={"button "}>Copy</button>
                            </div>
                        </div>
                    )}
                </main>
            )}

            {!overallLoading && !board.title && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '2rem',
                }}>
                    <h1 style={{
                        marginBottom: '1rem',
                    }}>Invalid Embed Code.</h1>
                    <p>Please paste the correct code, or double-check your embed settings.</p>
                </div>
            )}
            {!embed && alert[0] && <Alert message={alert[1]} />}
            {!embed && modalOpen === 'embed-board' && <EmbedCode type="board" id={id} enabled={board.visibility} onChangeHandler={(visibility) => setBoard({ ...board, visibility })} onCloseHandler={() => setModalOpen(null)} onConfirmation={deleteBoardHandler} throwAlert={throwAlert} />}
        </>
    )
}

export default EmbedBoard;