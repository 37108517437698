import { useNavigate } from 'react-router-dom';

import styles from './Header.module.css';

const Header = () => {
    const navigate = useNavigate();

    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <h1>Chibaku</h1>
                <button onClick={() => navigate('/signup')} className={styles.button + " home-btn"}>
                    Sign up
                </button>
            </div>
        </header>
    )
}

export default Header;