import { useState, useEffect } from 'react';
import axios from 'axios';

import Modal from '../UI/Modal';

const EmbedCode = (props) => {
    const [enabled, setEnabled] = useState(props.enabled === 'public');
    const copyHandler = () => {
        const text = `<iframe src="https://chibaku.com/embed/boards/${props.id}" width="100%" height="500"
        frameborder="0" allowfullscreen
        style="border-radius: 10px; max-width: 600px; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;"
        allow="clipboard-write"></iframe>`
        navigator.clipboard.writeText(text);

        props.throwAlert('Copied to clipboard');
        props.onCloseHandler();
    }

    useEffect(() => {
        const updateVisibility = async () => {
            try {
                const { data } = await axios.post('/boards/update-visibility', { boardId: props.id, visibility: enabled ? 'public' : 'private' }, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                if (data.status === 'ok') {
                    props.onChangeHandler(enabled ? 'public' : 'private');
                    props.throwAlert('Visibility updated successfully');
                } else {
                    props.throwAlert(data.message);
                }
            } catch (error) {
                props.throwAlert(error.message);
            }
        }
        if (props.enabled === 'public' && !enabled) {
            updateVisibility();
        } else if (props.enabled === 'private' && enabled) {
            updateVisibility();
        }
    }, [enabled]);

    return (
        <Modal title={`Embed ${props.type === 'board' ? 'Board' : 'Card'}`} actionHandler={copyHandler} actionText="Copy Code" onCloseHandler={props.onCloseHandler} cancelButtonText="Close" hideActionButton={!enabled} >
            <div style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1rem',
            }}>
                <input type="checkbox" id="enable" style={{
                    marginRight: '0.5rem',
                    height: '1.25rem',
                    width: '1.25rem',
                }} onChange={() => setEnabled(x => !x)} defaultChecked={props.enabled === 'public'} />
                <label htmlFor="enable" style={{
                    fontSize: '1.1rem',
                    userSelect: 'none',
                    cursor: 'pointer',
                }}>Enable embedding (public access)</label>
            </div>
            {enabled && (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    overflow: 'scroll',
                    backgroundColor: '#eee',
                    paddingLeft: '1rem',
                    height: '50px',
                }}>
                    <span style={{
                        whiteSpace: 'nowrap',
                    }}>{`<iframe src="https://chibaku.com/embed/boards/${props.id}" width="100%" height="500"
            frameborder="0" allowfullscreen
            style="border-radius: 10px; max-width: 600px; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;"
            allow="clipboard-write"></iframe>`}</span>
                </div>
            )}
        </Modal >
    )
}

export default EmbedCode;