import { useNavigate } from 'react-router-dom';

import styles from './CallToAction.module.css';

const CallToAction = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.main}>
            <h3>Ready to make copying<br />and managing text easier?</h3>
            <p>Chibaku is here to help you. All you have to do is sign up.</p>
            <button onClick={() => navigate('/signup')} className="home-btn">
                Sign up
            </button>
        </div>
    )
}

export default CallToAction