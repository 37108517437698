import styles from './Features.module.css';

const Features = () => {
    return (
        <div className={styles.main}>
            <div className="simple-container">
                <h1>Copying made easy.</h1>
                <p>Copy and use text every time?<br />Chibaku makes it simple.</p>
                <div className={styles.features}>
                    <div className={styles.feature}>
                        <i className="fal fa-bolt"></i>
                        <h3>Lightning speed</h3>
                        <p>Paste the text that you reuse all the time, and copy them in a single tap.</p>
                    </div>
                    <div className={styles.feature}>
                        <i className="fal fa-globe"></i>
                        <h3>Access everywhere</h3>
                        <p>All bubbles are synced in real-time. So you can copy and paste your texts across devices.</p>
                    </div>
                    <div className={styles.feature}>
                        <i className="fal fa-lock"></i>
                        <h3>Safety first</h3>
                        <p>All data is stored securely. This ensures your data and privacy is not compromised.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features;