import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import GoogleLogin from 'react-google-login';

import Alert from '../Alert';
import FullLoading from '../FullLoading';

import styles from './Signup.module.css';

const Login = () => {
    document.title = "Login - Chibaku";

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([false, false]);
    const [alert, setAlert] = useState([false, '']);
    const [pageLoading, setPageLoading] = useState(true);

    const throwAlert = (message) => {
        setAlert([true, message]);
        setTimeout(() => {
            setAlert([false, '']);
        }, 2800);
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) return window.location.href = '/boards';
        setPageLoading(false);
    }, []);

    const submitHandler = async (e) => {
        e.preventDefault();

        if (email === '' || email.length < 5 || !email.includes('@') || !email.includes('.')) {
            setError([true, error[1]]);
            return;
        }

        if (password === '' || password.length < 8) {
            setError([error[0], true]);
            return;
        }

        setLoading(true);

        const { data } = await axios.post('/users/login', {
            email,
            password
        })

        setLoading(false);

        if (data.status === "error") {
            return throwAlert(data.message);
        } else {
            localStorage.setItem('token', data.token);
            window.location.href = '/boards';
        }
    }

    useEffect(() => {
        if (email !== '' && email.length >= 5 && email.includes('@') && email.includes('.')) {
            setError([false, error[1]]);
        }
    }, [email]);

    useEffect(() => {
        if (password !== '' && password.length >= 8) {
            setError([error[0], false]);
        }
    }, [password]);

    const googleAuthLogin = async (response) => {
        setLoading(true);

        const { data } = await axios.post('/users/google-authentication', {
            idToken: response.tokenId
        })

        setLoading(false);

        if (data.status === 'ok') {
            localStorage.setItem('token', data.token);
            return window.location.href = '/boards';
        } else {
            return throwAlert("An error occured. Try again.");
        }
    }

    return (
        <>
            <section className={`${styles.signup} container`}>
                <h1>Login</h1>
                <form onSubmit={submitHandler}>
                    <input type="email" name="email" placeholder="E-mail Address" onChange={(e) => setEmail(e.target.value)} className={`form-input ${error[0] ? 'error-input' : ''}`} autoComplete="off" value={email} />
                    {error[0] && <p className="error-feedback"><i className="fal fa-exclamation-triangle"></i> Invalid Email Address</p>}
                    <input type="password" name="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} className={`form-input ${error[1] ? 'error-input' : ''}`} value={password} />
                    {error[1] && <p className="error-feedback"><i className="fal fa-exclamation-triangle"></i> Invalid Password</p>}
                    <button type="submit" className={'button ' + styles.button} disabled={loading}>{loading ? <i className="fad fa-spinner-third"></i> : 'Login'}</button>
                </form>
                <GoogleLogin
                    clientId='308745687426-nbfa0jeg63qp92qsdhccs178ei9ca3ah.apps.googleusercontent.com'
                    buttonText='Login with Google'
                    render={renderProps => (
                        <p className={styles.google}>Or, login using <span onClick={renderProps.onClick} disabled={renderProps.disabled}>Google</span></p>
                    )}
                    onSuccess={(res) => {
                        googleAuthLogin(res);
                    }}
                    onFailure={(res) => {
                        console.log(res);
                    }}
                    cookiePolicy={'single_host_origin'}
                />
                {/* <p className={styles.google}>Or, login using <Link to="/auth_google">Google</Link></p> */}
                <div className={styles.divider}></div>
                <p className={styles.login}>Don&apos;t have an account yet? <Link to="/signup">Sign up.</Link></p>
            </section>
            {alert[0] && <Alert message={alert[1]} />}
            {pageLoading && <FullLoading />}
        </>
    )
}

export default Login;