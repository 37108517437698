import axios from 'axios';

import styles from './Billing.module.css';

const Billing = (props) => {
    const subscriptionEndDate = `${props.subscriptionEndDate.getDate()}/${props.subscriptionEndDate.getMonth() + 1}/${props.subscriptionEndDate.getFullYear()}`;

    const openDashboardHandler = async () => {
        props.loadingIconTrue();

        const { data } = await axios.post('/users/new-stripe-portal-session', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });

        if (data.status === 'ok' && data.url) {
            return window.location.href = data.url;
        } else if (data.status === 'error' && data.message === "User not found") {
            props.throwAlert("User not found. Please login again.");
            localStorage.removeItem('token');
            return window.location.href = '/login';
        } else {
            return props.throwAlert("Something went wrong. Please try again.");
        }
    }

    return (
        <div className={styles.overlay} onClick={props.onCloseHandler}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <div className={styles.header}>
                    <h2>Billing</h2>
                    <button onClick={props.onCloseHandler}>
                        <i className="fal fa-times"></i>
                    </button>
                </div>
                <p className={styles.subP}>Welcome back, {props.user.name.split(" ")[0]}!</p>
                <p className={styles.dateP}>Next billing date: <span>{subscriptionEndDate}</span></p>
                <div className={styles.options}>
                    <div className={styles.option} onClick={openDashboardHandler}>
                        <i className="fal fa-user-alt"></i>
                        <div>
                            <p className={styles.title}>Billing details</p>
                            <p className={styles.desc}>Manage your billing address and more.</p>
                        </div>
                    </div>
                    <div className={styles.option + ' ' + styles.optionSecondary} onClick={openDashboardHandler}>
                        <i className="fal fa-credit-card"></i>
                        <div>
                            <p className={styles.title}>Payment methods</p>
                            <p className={styles.desc}>Manage your saved payment methods.</p>
                        </div>
                    </div>
                    <div className={styles.option} onClick={openDashboardHandler}>
                        <i className="fal fa-history"></i>
                        <div>
                            <p className={styles.title}>Payment history</p>
                            <p className={styles.desc}>View your Chibaku subscription history.</p>
                        </div>
                    </div>
                    <div className={styles.option} onClick={openDashboardHandler}>
                        <i className="fal fa-times"></i>
                        <div>
                            <p className={styles.title}>Cancel subscription</p>
                            <p className={styles.desc}>Terminate your Chibaku subscription.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Billing;