import { useState, useEffect } from 'react';
import axios from 'axios';

import Input from '../UI/Input';

import styles from './ProfileSettings.module.css';

const AccountSecurity = (props) => {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [inputError, setInputError] = useState([false, false, false]);
    const [loading, setLoading] = useState(false);

    const submitHandler = async () => {
        if (password.length < 8) {
            setInputError([true, inputError[1], inputError[2]]);
            return;
        }
        if (newPassword.length < 8) {
            setInputError([inputError[0], true, inputError[2]]);
            return;
        }
        if (confirmPassword !== newPassword) {
            setInputError([inputError[0], inputError[1], true]);
            return;
        }

        setLoading(true);

        const { data } = await axios.post('/users/update-password', { oldPassword: password, newPassword }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });

        setLoading(false);

        if (data.status === 'ok') {
            props.throwAlert(data.message);
            props.onCloseHandler();
        } else {
            props.throwAlert(data.message);
        }
    }

    useEffect(() => {
        if (password.length >= 8) {
            setInputError([false, inputError[1], inputError[2]]);
        }
    }, [password]);

    useEffect(() => {
        if (newPassword.length >= 8) {
            setInputError([inputError[0], false, inputError[2]]);
        }
    }, [newPassword]);

    useEffect(() => {
        if (confirmPassword === newPassword) {
            setInputError([inputError[0], inputError[1], false]);
        }
    }, [confirmPassword]);

    return (
        <div className={styles.overlay} onClick={props.onCloseHandler}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <h2>Change Password</h2>
                <div className={styles.content}>
                    <Input error={inputError[0]} errorText="Please enter a valid password." infoText="Enter your current password." type="password" placeholder="" focusDefault={false} value={password} changeHandler={(e) => setPassword(e.target.value)} />
                    <Input error={inputError[1]} errorText="Please choose a stronger password." infoText="Enter your new password." type="password" placeholder="" focusDefault={false} value={newPassword} changeHandler={(e) => setNewPassword(e.target.value)} />
                    <Input error={inputError[2]} errorText="Passwords do not match." infoText="Re-enter your new password." type="password" placeholder="" focusDefault={false} value={confirmPassword} changeHandler={(e) => setConfirmPassword(e.target.value)} />
                </div>
                <div className={styles.buttons}>
                    <button onClick={props.onCloseHandler} className={`button ${styles.cancel}`}>Close</button>
                    <button onClick={submitHandler} className={'button ' + styles.button} disabled={loading}>{loading ? <i className="fad fa-spinner-third"></i> : 'Update'}</button>
                </div>
            </div>
        </div >
    )
}

export default AccountSecurity;