import { Link } from 'react-router-dom';

import styles from './Hero.module.css';

const Hero = () => {
    document.body.classList.add('homepage');

    return (
        <div className={styles.hero}>
            <div className="simple-container">
                <h1>One tap to<br />copy text.</h1>
                <p className={styles.tagline}>Save and copy reusable text in an instant.</p>
                <p className={styles.emojis}>✏️ 📝 🕐 ☝️</p>
                <Link to="/signup">
                    <button className="home-btn">
                        Sign up
                    </button>
                </Link>
                <p className={styles.plogin}>Already joined?
                    <Link to='/login'>
                        Login here.
                    </Link>
                </p>
                <div className={styles.phBadge}>
                    <a href="https://www.producthunt.com/posts/chibaku?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chibaku" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=335831&theme=light" alt="Chibaku - One&#0032;tap&#0032;to&#0032;copy&#0032;text | Product Hunt" style={{ width: '250px', height: '54px' }} width="250" height="54" /></a>
                </div>
            </div>
        </div>
    )
}

export default Hero;