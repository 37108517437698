import { useState } from 'react';
import axios from 'axios';

import styles from './ViewUser.module.css';

const ViewUser = (props) => {
    const { user } = props;
    console.log(user);

    const [datePickerDate, setDatePickerDate] = useState(`${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`);

    const submitHandler = async () => {
        console.log(new Date(datePickerDate));
        const res = await axios.post(`/admin/manage-user/premium/${user._id}`, {
            renewalDate: new Date(datePickerDate)
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        if (res.data.status === 'ok') {
            alert('Success. User is now premium.');
        } else {
            alert('Error. Try again.');
        }
    }

    return (
        <div className={styles.overlay} onClick={props.closeModalHandler}>
            <div className={styles.main} onClick={(e) => e.stopPropagation()}>
                <div className={styles.header}>
                    <h1>Managing User</h1>
                    <button className={styles.close} onClick={props.closeModalHandler}>Close</button>
                </div>
                <p className={styles.premium}>{user.renewalDate < new Date() ? "Free Member" : "Premium Member 👑"}</p>
                <div className={styles.details}>
                    <p>Name: <span>{user.name}</span></p>
                    <p>Email: <span>{user.email}</span></p>
                    <p>UID: <span>{user._id}</span></p>
                    <p>Activities logged today: <span>{user.todayJournalCount}</span></p>
                    <p>Registered on: <span>{`${new Date(user.createdAt).toLocaleString('default', { month: 'long' })} ${new Date(user.createdAt).getDate()}, ${new Date(user.createdAt).getFullYear()}`}</span></p>
                    {user.renewalDate > new Date() && (
                        <p>Subscribed on: <span>{`${new Date(user.subscriptionStart).toLocaleString('default', { month: 'long' })} ${new Date(user.subscriptionStart).getDate()}, ${new Date(user.subscriptionStart).getFullYear()}`}</span></p>
                    )}
                    {user.renewalDate > new Date() && (
                        <p>Renewal Date: <span>{`${new Date(user.renewalDate).toLocaleString('default', { month: 'long' })} ${new Date(user.renewalDate).getDate()}, ${new Date(user.renewalDate).getFullYear()}`}</span></p>
                    )}
                    <div className={styles.premiumDate}>
                        <h2>Make this user Premium until:</h2>
                        <input type="date" value={datePickerDate} onChange={(e) => setDatePickerDate(e.target.value)} />
                        <button onClick={submitHandler} className="button">Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewUser;