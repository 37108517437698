import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import App from './App';

axios.defaults.baseURL = 'https://api.chibaku.com';

ReactDOM.render(
    <App />,
    document.querySelector('#app')
);