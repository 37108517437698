import { useState, useEffect } from 'react';
import axios from 'axios';

import BoardPlaceholder from './BoardPlaceholder';

import styles from './BoardsList.module.css';

const BoardsList = (props) => {

    return (
        <>
            <section className={styles.main}>
                <div className={styles.container}>
                    <h1>Your Boards</h1>
                    <div className={styles.boards}>
                        <BoardPlaceholder newBoardHandler={props.newBoardHandler} type="new-board-button" bgColor="#091e422a" />
                        {props.boards.map(board => (
                            <BoardPlaceholder key={board._id} id={board._id} title={board.title} bgColor={board.backgroundColor} />
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default BoardsList;