import { Link } from 'react-router-dom';

import styles from './BoardUnauthorized.module.css';

const BoardUnauthorized = () => {
    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <h2>Unauthorized Board.</h2>
                <div className={styles.content}>
                    <p>Seems like you don't have the permission to view this board.</p>
                    <p>If you think this is a mistake, please contact the board owner.</p>
                </div>
                <div className={styles.buttons}>
                    <Link to="/boards">
                        <button className="button">Back to My Boards</button>
                    </Link>
                </div>
            </div>
        </div >
    )
}

export default BoardUnauthorized;