import { useState } from 'react';

import EditCard from './EditCard';

import styles from './Card.module.css';

const Card = (props) => {
    const [editModalShow, setEditModalShow] = useState(false);
    const [watingClick, setWatingClick] = useState(null);
    const [lastClick, setLastClick] = useState(0);
    const [stylesCard, setStylesCard] = useState(styles.card);

    const onClickHandler = (e) => {
        if (lastClick && e.timeStamp - lastClick < 250 && watingClick) {
            // double click
            setEditModalShow(true);
            setLastClick(0);
            clearTimeout(watingClick);
            setWatingClick(null);
        } else {
            // single click
            navigator.clipboard.writeText(props.text);
            setStylesCard(styles.card + ' ' + styles.shrink)
            setTimeout(() => setStylesCard(styles.card), 100);
            setLastClick(e.timeStamp);
            setWatingClick(setTimeout(() => {
                setWatingClick(null);
            }, 251))
        }
    }

    const selectedCardHandler = (e) => {
        e.stopPropagation();
        props.selectCardHandler();
    }

    return (
        <>
            <div onClick={onClickHandler} onMouseUp={() => setStylesCard(styles.card)} className={stylesCard}>
                {props.text.split('\n').map((item, index) => {
                    return <p className={item.length === 0 ? styles.space : ''} key={index}>{item}</p>
                })}
                <button onClick={selectedCardHandler} className={`${styles.checkbox} ${props.selected ? styles.active : ''}`}>
                    {props.selected && <i className="fas fa-check"></i>}
                </button>
            </div>
            {editModalShow && <EditCard onCloseHandler={() => setEditModalShow(false)} id={props.id} text={props.text} boardId={props.boardId} onCardDelete={props.onCardDelete} onCardEdit={props.onCardEdit} />}
        </>
    )
}

export default Card;