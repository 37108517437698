import Modal from '../UI/Modal';

const DeleteConfirmation = (props) => {
    return (
        <Modal title={`Delete ${props.type[0].toUpperCase()}${props.type.slice(1)}?`} dangerColor={true} actionHandler={props.onConfirmation} actionText="Delete" actionLoading={props.loading} onCloseHandler={props.onCloseHandler}>
            <p>Are you sure you want to delete this {props.type}?</p>
        </Modal>
    )
}

export default DeleteConfirmation;