const Input = (props) => {
    return (
        <>
            <input className={props.error ? 'error-input' : ''} type={props.type} placeholder={props.placeholder} autoFocus={props.focusDefault} value={props.value} onChange={props.changeHandler} />
            {!props.error && <p className="input-feedback">{props.infoText}</p>}
            {props.error && <p className="input-feedback error"><i className="fal fa-exclamation-triangle"></i> {props.errorText}</p>}
        </>
    )
}

export default Input;