import { useEffect } from "react";

const Logout = () => {
    useEffect(() => {
        localStorage.removeItem('token');
        window.location.href = '/';
    }, []);

    return (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            Please wait while we log you out...
        </div>
    )
}

export default Logout;