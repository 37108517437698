import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import RecentUsers from './RecentUsers';
import StatsCard from './StatsCard';
import ViewUser from './ViewUser';
import AllUsers from './AllUsers';

import styles from "./Admin.module.css";

const Admin = () => {
    document.title = "Chibaku Admin Panel";

    const [recentUsers, setRecentUsers] = useState([]);
    const [stats, setStats] = useState({});
    const [viewUser, setViewUser] = useState([false, null]);
    const [allUsers, setAllUsers] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        const { data } = await axios.post('/admin/is-admin', {}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });

        if (data.status === 'ok') {
            setLoading(false);
        } else {
            return window.location.href = '/boards';
        }
    }, []);

    useEffect(async () => {
        const { data } = await axios.post('/admin/recent-users', {}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });

        if (data.status === "ok") {
            setRecentUsers(data.users);
        } else {
            console.log(data.message);
        }
    }, []);

    useEffect(async () => {
        const { data } = await axios.post('/admin/stats', {}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });

        if (data.status === 'ok') {
            setStats(data.stats)
        } else {
            console.log(data.message);
        }
    }, [])

    const viewUserHandler = async (uid) => {
        const { data } = await axios.post(`/admin/user-details/${uid}`, {}, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });

        if (data.status === 'ok') {
            setViewUser([data.user, true]);
            setAllUsers(false);
        } else {
            console.log(data.message);
        }
    }

    return (
        <>
            {!loading && (
                <div className={styles.main}>
                    <div className={styles.container}>
                        <div className={styles.header}>
                            <h1>Admin Panel</h1>
                            <Link to="/boards">
                                Back to Chibaku
                            </Link>
                        </div>
                        <div className={styles.main1}>
                            <RecentUsers recentUsers={recentUsers} viewUserHandler={viewUserHandler} viewAllUsers={() => setAllUsers(true)} />
                            <div className={styles.cards}>
                                <h2>Stats</h2>
                                <StatsCard stat={["Number of users", stats.numberOfUsers]} />
                                <StatsCard stat={["Number of premium users", stats.numberOfPremiumUsers]} />
                                <StatsCard stat={["Number of boards created", stats.numberOfBoards]} />
                                <StatsCard stat={["Number of cards created", stats.numberOfCards]} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {loading && <div className={styles.loading}><p>Please wait a minute...</p></div>}
            {viewUser[0] && <ViewUser user={viewUser[0]} closeModalHandler={() => setViewUser([null, false])} />}
            {allUsers && <AllUsers closeModalHandler={() => setAllUsers(false)} viewUserHandler={viewUserHandler} />}
        </>
    )
}

export default Admin;