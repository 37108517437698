import styles from './Modal.module.css';

const Modal = (props) => {
    return (
        <div className={styles.overlay} onClick={props.onCloseHandler}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <h2>{props.title}</h2>
                <div className={styles.content}>
                    {props.children}
                </div>
                <div className={styles.buttons}>
                    <button className={"button " + styles.cancel} onClick={props.onCloseHandler}>{props.cancelButtonText ? props.cancelButtonText : 'Cancel'}</button>
                    {props.hideActionButton ? null : <button style={{ backgroundColor: props.dangerColor === true ? 'var(--error-color)' : 'var(--accent-color)', whiteSpace: 'nowrap' }} onClick={props.actionHandler} className="button" disabled={props.actionLoading}>{props.actionLoading ? <i className="fad fa-spinner-third"></i> : props.actionText}</button>}
                </div>
            </div>
        </div>
    )
}

export default Modal;