import { Link } from 'react-router-dom';

import styles from './Pricing.module.css';

const Pricing = () => {
    return (
        <div className={styles.main}>
            <div className="simple-container">
                <h1>Pricing and Plans</h1>
                <p>Choose a plan that suits your needs.<br />We have a plan for everyone.</p>
                <div className={styles.plans}>
                    <div className={styles.plan}>
                        <h3>Personal</h3>
                        <h2>Free</h2>
                        <p>All essential features to get you started with Chibaku.</p>
                        <Link to="/signup">
                            <button className="home-btn">
                                Get started
                            </button>
                        </Link>
                        <ul>
                            <li>5 boards</li>
                            <li>20 cards per board</li>
                            <li className={styles.nope}>Early access to features</li>
                            <li className={styles.nope}>Priority support</li>
                            <li className={styles.nope}>Export to CSV (coming soon)</li>
                        </ul>
                    </div>
                    <div className={styles.plan}>
                        <h3>Premium</h3>
                        <h2>$4.99/mo.</h2>
                        <p>Everything you need to super-charge your productivity.</p>
                        <Link to="/signup">
                            <button className="home-btn">
                                Get started
                            </button>
                        </Link>
                        <ul>
                            <li>Unlimited boards</li>
                            <li>Unlimited cards</li>
                            <li>Early access to features</li>
                            <li>Priority support</li>
                            <li>Export to CSV (coming soon)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pricing;