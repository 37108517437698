import { useState, useEffect } from 'react';
import axios from 'axios';

import Input from '../UI/Input';

import styles from './ProfileSettings.module.css';

const ProfileSettings = (props) => {
    const [name, setName] = useState(props.user.name);
    const [email, setEmail] = useState(props.user.email);
    const [inputError, setInputError] = useState([false, false]);
    const [loading, setLoading] = useState(false);
    const [newProfileImage, setNewProfileImage] = useState(null);

    const submitHandler = async () => {
        if (!name.trim().length > 1) return setInputError([true, inputError[1]]);
        if (!email.trim().length > 4 || !email.includes('@') || !email.includes('.')) return setInputError([inputError[0], true]);

        setLoading(true);

        const { data } = await axios.post('/users/update-user-details', {
            name,
            email,
            profilePicture: newProfileImage
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });

        setLoading(false);

        if (data.status === 'ok') {
            props.throwAlert('Your details have been updated.');
            props.setUser({ ...props.user, ...data.user });
            props.onCloseHandler();
        } else {
            props.throwAlert('Something went wrong. Please try again.');
        }
    }

    useEffect(() => {
        if (name.trim().length > 1) setInputError([false, inputError[1]]);
    }, [name]);

    useEffect(() => {
        if (email.trim().length > 4 && email.includes('@') && email.includes('.')) setInputError([inputError[0], false]);
    }, [email]);

    const newProfilePictureHandler = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setNewProfileImage(reader.result);
            }
        } else {
            setNewProfileImage(null);
        }
    }

    return (
        <div className={styles.overlay} onClick={props.onCloseHandler}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <h2>Profile Settings</h2>
                <div className={styles.content}>
                    <div className={styles.profile}>
                        <div className={styles.avatar}>
                            <img src={newProfileImage ? newProfileImage : props.user.profilePicture} alt={props.user.name} />
                            {/* <div className={styles.edit}>
                                <input onChange={newProfilePictureHandler} className={styles.filePicker} id="file" type="file" accept="image/*" />
                                <label className={styles.fileLabel} htmlFor="file">
                                    <i className="fas fa-camera"></i>
                                </label>
                            </div> */}
                        </div>
                        <div className={styles.name}>
                            <p>{props.user.name}</p>
                            <span>{props.user.email}</span>
                        </div>
                    </div>
                    <Input error={inputError[0]} errorText="Please enter a valid name." infoText="Enter your display name." type="text" placeholder="John Doe" focusDefault={false} value={name} changeHandler={(e) => setName(e.target.value)} />
                    <Input error={inputError[1]} errorText="Please enter a valid email address." infoText="Enter your email address." type="email" placeholder="john@example.com" focusDefault={false} value={email} changeHandler={(e) => setEmail(e.target.value)} />
                </div>
                <div className={styles.buttons}>
                    <button onClick={props.onCloseHandler} className={`button ${styles.cancel}`}>Close</button>
                    <button onClick={submitHandler} className={'button ' + styles.button} disabled={loading}>{loading ? <i className="fad fa-spinner-third"></i> : 'Update'}</button>
                </div>
            </div>
        </div >
    )
}

export default ProfileSettings;