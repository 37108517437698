import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Modal from '../UI/Modal';

import Input from '../UI/Input';

const NewBoard = (props) => {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const submitHandler = async () => {
        if (name.length === 0 || name.length > 16) return setError(true);

        setLoading(true);

        const { data } = await axios.post('/boards/new-board', { title: name }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });

        setLoading(false);

        if (data.status === 'ok') {
            props.setModalOpen(null)
            props.throwAlert('Board created successfully!');
            navigate('/boards/' + data.boardId);
        } else {
            props.throwAlert(data.message);
        }

    }

    const inputChangeHandler = (e) => {
        const text = e.target.value;
        if (text.length <= 16) return setName(text);
        if (text.length > 16) {
            if (name.length - text.length === 1) return setName(text);
            return setName(name);
        }
    }

    useEffect(() => {
        if (name.length > 1 && name.length <= 16) return setError(false);
    }, [name]);

    return (
        <Modal title="New Board" actionHandler={submitHandler} actionText="Create" actionLoading={loading} onCloseHandler={() => props.setModalOpen(null)}>
            <Input error={error} errorText="Please enter a valid name." infoText={name.length + '/16'} type="text" placeholder="Board Name" focusDefault={true} value={name} changeHandler={inputChangeHandler} />
        </Modal >
    )
}

export default NewBoard;