import styles from './StatsCard.module.css';

const StatsCard = (props) => {
    return (
        <div className={styles.card}>
            <p className={styles.title}>
                {props.stat[0]}
            </p>
            <p className={styles.number}>
                {props.stat[1]}
            </p>
        </div>
    )
}

export default StatsCard;