import { useState } from 'react';
import { Link } from 'react-router-dom';

import ProfileSettings from '../Modals/ProfileSettings';
import AccountSecurity from '../Modals/AccountSecurity';

import UpgradeHero from '../Premium/UpgradeHero';
import Billing from '../Premium/Billing';

import loadingGif from './loading.gif';

import styles from './Header.module.css';

const Header = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(null);

    const openModal = (modal) => {
        setModalOpen(modal);
        setIsOpen(false);
    }

    return (
        <>
            <header className={styles.header}>
                {isOpen && <div className={styles.overlay} onClick={() => setIsOpen(false)}></div>}
                <div className={styles.container}>
                    <h1 className={styles.title}>
                        <Link to="/boards">
                            Chibaku
                        </Link>
                    </h1>
                    <div className={styles.account}>
                        <button onClick={() => setIsOpen(true)} className={styles.button + ' ' + styles.account}>
                            <img src={props.user.profilePicture ? props.user.profilePicture : loadingGif} alt={props.user.name} />
                        </button>
                        {isOpen && (
                            <div className={styles.dropdown}>
                                <div className={styles.profile}>
                                    <div className={styles.avatar}>
                                        <img src={props.user.profilePicture} alt={props.user.name} />
                                    </div>
                                    <div className={styles.name}>
                                        <p>{props.user.name}</p>
                                        <span>{props.user.email}</span>
                                    </div>
                                </div>
                                <div className={styles.divider}></div>
                                <button onClick={() => openModal('settings')}>Profile</button>
                                <button onClick={() => openModal('password')}>Security</button>
                                {props.isPremium && <button onClick={() => openModal('billing')}>Billing</button>}
                                {!props.isPremium && <button onClick={() => openModal('premium')}>Premium</button>}
                                <div className={styles.divider}></div>
                                <Link to="/logout">
                                    <button>Logout</button>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </header>
            {modalOpen === 'settings' && <ProfileSettings user={props.user} setUser={props.setUser} throwAlert={props.throwAlert} onCloseHandler={() => setModalOpen(null)} />}
            {modalOpen === 'password' && <AccountSecurity throwAlert={props.throwAlert} onCloseHandler={() => setModalOpen(null)} />}
            {modalOpen === 'premium' && <UpgradeHero throwAlert={props.throwAlert} onCloseHandler={() => setModalOpen(null)} />}
            {modalOpen === 'billing' && <Billing user={props.user} subscriptionEndDate={new Date(props.user.renewalDate)} loadingIconTrue={() => props.setUser({ ...props.user, profilePicture: null })} throwAlert={props.throwAlert} onCloseHandler={() => setModalOpen(null)} />}
        </>
    )
}

export default Header;