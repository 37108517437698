import styles from './Default.module.css';

const LimitReached = (props) => {
    const upgradeButtonClickHandler = () => {
        props.onCloseHandler();
        props.openUpgradeHero();
    }

    return (
        <div className={styles.overlay} onClick={props.onCloseHandler}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <h2>Uh-oh. Free Limit Reached</h2>
                {props.limitType === 'card' && <p>You can only add upto 100 cards with the free plan. 😔</p>}
                {props.limitType === 'board' && <p>You can only add upto 5 boards with the free plan. 😔</p>}
                <p>Upgrade to Chibaku premium to get: 💎</p>
                <ul>
                    <li>Unlimited boards</li>
                    <li>Unlimited cards</li>
                    <li>Priority support</li>
                    <li>Export to CSV (coming soon)</li>
                    <li>Team collaboration (coming soon)</li>
                    <li>&amp; so much more.</li>
                </ul>
                <div className={styles.buttons}>
                    <button onClick={props.onCloseHandler} className={styles.cancel}>Not yet</button>
                    <button onClick={upgradeButtonClickHandler} className={styles.action}>Upgrade</button>
                </div>
            </div>
        </div>
    )
}

export default LimitReached;